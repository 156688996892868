<template>
  <div class="sheet-layout">
    <div>
      <Profile :sheet="sheet" />
      <Character :sheet="sheet" />
    </div>
    <div>
      <Weapons :sheet="sheet" />
      <Armor :sheet="sheet" />
      <Inventory :sheet="sheet" />
      <Currency :sheet="sheet" />
    </div>
    <div style="flex: 0 0 auto">
      <Skills :sheet="sheet" />
    </div>
    <div>
      <Traits :sheet="sheet" />
      <Features :sheet="sheet" />
      <Notes :sheet="sheet" />
    </div>
    <Spells :sheet="sheet" />
  </div>
</template>

<script>
import Profile from './Profile'
import Character from './Character'
import Weapons from './Weapons'
import Armor from './Armor'
import Skills from './Skills'
import Inventory from './Inventory'
import Currency from './Currency'
import Spells from './Spells'
import Traits from './Traits'
import Features from './Features'
import Notes from './Notes'

export default {
  props: [ 'sheet' ],
  components: {
    Profile,
    Character,
    Weapons,
    Armor,
    Skills,
    Inventory,
    Currency,
    Spells,
    Traits,
    Features,
    Notes
  }
}
</script>

<style lang="scss">
.sheet-layout {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  > * {
    padding: 0 1rem;
    flex: 1;
    min-width: 30em;
    box-sizing: border-box;

    @media (max-width: 750px) {
      .sheet-layout > * {
        padding-bottom: 1rem 0;
        width: 100%;
        min-width: auto;
        box-sizing: border-box;
      }
    }
  }
}
</style>
