<template>
  <div id="traits">
    <h2 class="capitalize">{{ $t('traits') }}</h2>
    <div>
      <textarea class="notes" v-model="sheet.input.traits" rows="10"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ]
}
</script>
