<template>
  <div id="inventory">
    <h2 class="capitalize">{{ $t('inventory') }}</h2>
    <div>
      <button @click="handleShowAddWeaponModal">Add weapon</button>
      <button>Add armor</button>
      <button>Add item</button>
    </div>
    <table>
      <tr class="bold capitalize">
        <td></td>
        <td>{{ $t('quantity_short') }}</td>
        <td>{{ $t('name') }}</td>
        <td>{{ $t('weight') }} (lb / unit)</td>
      </tr>
      <tr v-for="(item, index) in sheet.input.items" :key="index">
        <td><button @click="handleRemove(index)">x</button></td>
        <td><input type="number" v-model="item.quantity" min="1" max="999"></td>
        <td class="capitalize"><input type="text" v-model="item.name"></td>
        <td><input type="number" v-model="item.weight" min="1" max="9999"></td>
      </tr>
      <tr class="bold">
        <td colspan="2" style="text-align: right">Total</td>
        <td><input type="number" v-model="weight" min="1" max="9999" readonly></td>
      </tr>
    </table>
  </div>
</template>

<script>
import AddWeaponModal from './AddWeaponModal'

export default {
  props: [ 'sheet' ],
  computed: {
    weight() {
      return this.sheet.input.items.reduce((accum, { weight, quantity }) => accum + (Number(quantity) * Number(weight)), 0)
    }
  },
  methods: {
    handleRemove(index) {
      this.sheet.input.items.splice(index, 1)
    },
    handleShowAddWeaponModal() {
      this.$modal.show(
        AddWeaponModal,
        {
          submit: (data) => {
            this.sheet.input.items.push(data)
          }
        },
        {  width: '100%', height: '100%', scrollable: true }
      )
    }
  }
}
</script>
