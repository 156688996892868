const toModifier = (value) => Math.floor((value - 10) / 2)

const computedValue = (get, value = 0) => new Proxy({ value }, { get, set() {} })

function createSheet(input) {
  const computed = {}

  computed.modifiers = {
    strength: computedValue(() => toModifier(input.attributes.strength)),
    dexterity: computedValue(() => toModifier(input.attributes.dexterity)),
    constitution: computedValue(() => toModifier(input.attributes.constitution)),
    intelligence: computedValue(() => toModifier(input.attributes.intelligence)),
    wisdom: computedValue(() => toModifier(input.attributes.wisdom)),
    charisma: computedValue(() => toModifier(input.attributes.charisma))
  }

  computed.initiative = computedValue(() => computed.modifiers.dexterity.value + Number(input.modifiers.initiative))
  computed.modifiers.armorPenalty = computedValue(() =>
    input.items
      .filter(({ type }) => type === 'armor')
      .reduce((accum, next) => accum - next.armorPenalty, 0)
  )

  return {
    input,
    computed
  }
}

module.exports = {
  createSheet,
  toModifier
}
