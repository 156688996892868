<template>
  <div>
    <div id="attributes">
      <h2 class="capitalize">{{ $t('attributes') }}</h2>
      <table class="capitalize">
        <tr class="bold">
          <td>{{ $t('attributes') }}</td>
          <td>{{ $t('point_plural') }}</td>
          <td>{{ $t('modifier') }}</td>
        </tr>
        <tr>
          <td>{{ $t('strength') }} (<span class="uppercase">{{ $t('strength_short') }}</span>)</td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.input.attributes.strength"></td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.computed.modifiers.strength.value" readonly></td>
        </tr>
        <tr>
          <td>{{ $t('dexterity') }} (<span class="uppercase">{{ $t('dexterity_short') }}</span>)</td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.input.attributes.dexterity"></td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.computed.modifiers.dexterity.value" readonly></td>
        </tr>
        <tr>
          <td>{{ $t('constitution') }} (<span class="uppercase">{{ $t('constitution_short') }}</span>)</td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.input.attributes.constitution"></td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.computed.modifiers.constitution.value" readonly></td>
        </tr>
        <tr>
          <td>{{ $t('intelligence') }} (<span class="uppercase">{{ $t('intelligence_short') }}</span>)</td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.input.attributes.intelligence"></td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.computed.modifiers.intelligence.value" readonly></td>
        </tr>
        <tr>
          <td>{{ $t('wisdom') }} (<span class="uppercase">{{ $t('wisdom_short') }}</span>)</td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.input.attributes.wisdom"></td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.computed.modifiers.wisdom.value" readonly></td>
        </tr>
        <tr>
          <td>{{ $t('charisma') }} (<span class="uppercase">{{ $t('charisma_short') }}</span>)</td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.input.attributes.charisma"></td>
          <td><input type="number" min="-9999" max="9999" v-model="sheet.computed.modifiers.charisma.value" readonly></td>
        </tr>
      </table>
    </div>
    <div id="hitpoints">
      <table class="capitalize">
        <tr class="bold">
          <td>{{ $t('health') }}</td>
          <td>{{ $t('wounds') }}</td>
        </tr>
        <tr>
          <td><input type="number" v-model="sheet.input.health" min="0" max="9999"></td>
          <td><input type="number" v-model="sheet.input.wounds" min="0" max="9999"></td>
        </tr>
      </table>
    </div>
    <div id="initiative">
      <table class="capitalize">
        <tr class="bold">
          <td>{{ $t('initiative') }}</td>
          <td>{{ $t('modifier_short') }}. {{ $t('dexterity_short') }}.</td>
          <td>{{ $t('modifier_short') }}. {{ $t('initiative_short') }}.</td>
        </tr>
        <tr>
          <td>
            <input type="number" v-model="sheet.computed.initiative.value" min="-9999" max="9999" readonly>
          </td>
          <td>
            <input type="number" :value="sheet.computed.modifiers.dexterity.value" min="-9999" max="9999" readonly>
          </td>
          <td>
            <input type="number" v-model="sheet.input.modifiers.initiative" min="-9999" max="9999">
          </td>
        </tr>
      </table>
    </div>
    <div id="ca">

    </div>
    <div id="save">

    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ]
}
</script>
