<template>
  <div id="armor">
    <h2 class="capitalize">{{ $t('armor') }}</h2>
    <div v-for="(piece, index) in pieces" :key="index">
      <table class="armor">
        <tr class="capitalize bold">
          <td colspan="3">{{ $t('name') }}</td>
          <td>{{ $t('bonus') }}</td>
        </tr>
        <tr>
          <td colspan="3">{{ piece.name }}</td>
          <td>{{ piece.armorClass }}</td>
        </tr>
        <tr class="capitalize bold">
          <td>{{ $t('type') }}</td>
          <td>{{ $t('penalty') }}</td>
          <td>{{ $t('spell_chance') }}</td>
          <td>{{ $t('weight') }} (lb)</td>
        </tr>
        <tr>
          <td>{{ piece.armorKind }}</td>
          <td>{{ piece.armorPenalty }}</td>
          <td>{{ piece.armorSpellFailure }}</td>
          <td>{{ piece.weight }}</td>
        </tr>
        <tr class="capitalize bold">
          <td colspan="4">{{ $t('properties') }}</td>
        </tr>
        <tr>
          <td colspan="4">{{ piece.armorProperties || 'N/A' }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ],
  computed: {
    pieces() {
      return this.sheet.input.items.filter(({ type, inUse }) => type === 'armor' && inUse)
    }
  }
}
</script>

<style lang="scss">
.armor {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin-bottom: 1rem;

  td {
    border: 1px solid black;
  }
}
</style>
