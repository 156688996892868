import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueModal from 'vue-js-modal'
import App from './App.vue'
import './styles/index.scss'
import './register-service-worker'

Vue.use(VueI18n)
Vue.use(VueModal)

const locale = localStorage.getItem('lang') || 'en'

const app = new Vue({
  render: (h) => h(App),
  i18n: new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
      'en': require('../lang/en.json'),
      'es': require('../lang/es.json')
    }
  })
})

app.$mount('#root')
