<template>
  <form class="modal" ref="form" @submit.prevent="handleSubmit">
    <div class="modal__header">
      <h2 class="modal__title" style="text-align: center">
        Add weapon
      </h2>
    </div>
    <div class="modal__body">
      <div class="form capitalize">
        <div class="form__row">
          <div class="form__heading">
            Item metadata
          </div>
        </div>
        <div class="form__row form__row--xs-col">
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('name') }}</label>
            <input class="form__input" type="text" required name="name">
          </div>
          <div class="form__field">
            <label class="form__label">{{ $t('type') }}</label>
            <input class="form__input" type="text" value="weapon" name="type" readonly>
          </div>
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('quantity') }}</label>
            <input class="form__input" type="number" min="1" max="9999" :value="1" required name="quantity">
          </div>
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('weight') }} (lb)</label>
            <input class="form__input" type="number" min="0" max="9999" :value="0" required name="weight">
          </div>
        </div>
        <div class="form__row form__row--xs-col">
        </div>
        <div class="form__row">
          <div class="form__heading">
            Weapon metadata
          </div>
        </div>
        <div class="form__row form__row--xs-col">
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('type') }}</label>
            <input class="form__input" type="text" name="weaponType">
          </div>
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('reach') }} (ft)</label>
            <input class="form__input" type="text" value="0" name="weaponReach">
          </div>
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('ammo') }}</label>
            <input class="form__input" type="text" value="N/A" name="weaponAmmo">
          </div>
        </div>
        <div class="form__row form__row--xs-col">
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('damage') }}</label>
            <input class="form__input" type="text" required name="weaponDamage">
          </div>
          <div class="form__field">
            <label class="form__label">{{ $t('bonus') }}</label>
            <input class="form__input" type="text" value="0" name="weaponBonus">
          </div>
          <div class="form__field">
            <label class="form__label form__label--required">{{ $t('critical') }}</label>
            <input class="form__input" type="text" required name="weaponCritical">
          </div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <button type="submit">{{ $t('add') }}</button>
      <button type="button" @click="handleCancel">{{ $t('cancel') }}</button>
    </div>
  </form>
</template>

<script>
export default {
  props: [ 'submit' ],
  methods: {
    handleSubmit(event) {
      const data = new FormData(event.target)
      const item = {}

      data.forEach((value, key) => item[key] = value)

      this.submit(item)

      this.$refs.form.reset()
      this.$emit('close')
    },
    handleCancel() {
      this.$refs.form.reset()
      this.$emit('close')
    }
  }
}
</script>
