const valueFromIndexOrDefault = (valueOrTuple, index) => Array.isArray(valueOrTuple) ? valueOrTuple[index] : valueOrTuple

const createEnum = (list) => Object.freeze(list.reduce((accum, next) => ({ ...accum, [valueFromIndexOrDefault(next, 0)]: valueFromIndexOrDefault(next, 1) }), {}))

const attributes = createEnum([ 'strength', 'dexterity', 'constitution', 'intelligence', 'wisdom', 'charisma' ])
const attributesShort = createEnum([
  [ attributes.strength, 'str' ],
  [ attributes.dexterity, 'dex' ],
  [ attributes.constitution, 'con' ],
  [ attributes.intelligence, 'int' ],
  [ attributes.wisdom, 'wis' ],
  [ attributes.charisma, 'cha' ]
])

module.exports = {
  attributes,
  attributesShort
}
