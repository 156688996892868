<template>
  <div id="weapons">
    <h2 class="capitalize">{{ $t('weapons') }}</h2>
    <div v-for="(weapon, index) in weapons" :key="index">
      <table class="weapon">
        <tr class="capitalize bold">
          <td colspan="3">{{ $t('name') }}</td>
          <td>{{ $t('bonus') }}</td>
          <td>{{ $t('critical') }}</td>
        </tr>
        <tr>
          <td colspan="3">{{ weapon.name }}</td>
          <td>0</td>
          <td>{{ weapon.weaponCritical }}</td>
        </tr>
        <tr class="capitalize bold">
          <td>{{ $t('type') }}</td>
          <td>{{ $t('reach') }} (ft)</td>
          <td>{{ $t('ammo') }}</td>
          <td colspan="2">{{ $t('damage') }}</td>
        </tr>
        <tr>
          <td>{{ weapon.weaponKind }}</td>
          <td>{{ weapon.weaponReach }}</td>
          <td>{{ weapon.weaponAmmo }}</td>
          <td colspan="2">{{ weapon.weaponDamage }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ],
  computed: {
    weapons() {
      return this.sheet.input.items.filter(({ type }) => type === 'weapon')
    }
  }
}
</script>

<style lang="scss">
.weapon {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin-bottom: 1rem;

  td {
    border: 1px solid black;
  }
}
</style>
