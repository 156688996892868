<template>
  <div id="skills">
    <h2 class="capitalize">{{ $t('skills') }}</h2>
    <div style="width: 100%; overflow-x: auto">
      <table class="capitalize">
        <tr class="bold capitalize">
          <td></td>
          <td>{{ $t('name') }}</td>
          <td>{{ $t('attributes_short') }}.</td>
          <td>{{ $t('total') }}</td>
          <td>=</td>
          <td>{{ $t('modifier_short') }}. {{ $t('attributes_short') }}.</td>
          <td>+</td>
          <td>{{ $t('level') }}</td>
          <td>+</td>
          <td>{{ $t('other') }} {{ $t('modifier_short') }}.</td>
          <td>+</td>
          <td>{{ $t('armor') }} {{ $t('modifier_short') }}.</td>
        </tr>
        <tr v-for="skill in skills" :key="skill.name">
          <td><input type="checkbox" v-model="skill.isClassSkill"></td>
          <td>{{ $t(skill.name) }}</td>
          <td class="uppercase">{{ $t(`${skill.attr}_short`) }}</td>
          <td>
            <input type="number" :value="calculateTotal(skill)" min="-9999" max="9999" readonly="readonly">
          </td>
          <td>=</td>
          <td>
            <input type="number" v-model="sheet.computed.modifiers[skill.attr].value" min="-9999" max="9999" readonly="readonly">
          </td>
          <td>+</td>
          <td>
            <input type="number" v-model="skill.level" min="0" max="9999">
          </td>
          <td>+</td>
          <td>
            <input type="number" v-model="skill.modifiers" min="0" max="9999">
          </td>
          <td v-if="skill.armorPenalty">+</td>
          <td>
            <input v-if="skill.armorPenalty" type="number" :value="sheet.computed.modifiers.armorPenalty.value" min="-9999" max="9999" readonly="readonly">
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ],
  computed: {
    skills() {
      return [ ...this.sheet.input.skills ].sort((a, z) => this.$t(a.name).localeCompare(this.$t(z.name)))
    }
  },
  methods: {
    calculateTotal(skill) {
      const values = [
        this.sheet.computed.modifiers[skill.attr].value,
        skill.level,
        skill.modifiers
      ]

      if (skill.armorPenalty) {
        values.push(this.sheet.computed.modifiers.armorPenalty.value)
      }

      return values.reduce((accum, next) => accum + Number(next), 0)
    }
  }
}
</script>
