<template>
  <div id="currency">
    <h2 class="capitalize">{{ $t('currency') }}</h2>
    <table class="capitalize">
      <tr>
        <td>{{ $t('currency:copper') }}</td>
        <td><input type="number" min="0" max="9999" v-model="sheet.input.currency.copper"></td>
      </tr>
      <tr>
        <td>{{ $t('currency:silver') }}</td>
        <td><input type="number" min="0" max="9999" v-model="sheet.input.currency.silver"></td>
      </tr>
      <tr>
        <td>{{ $t('currency:gold') }}</td>
        <td><input type="number" min="0" max="9999" v-model="sheet.input.currency.gold"></td>
      </tr>
      <tr>
        <td>{{ $t('currency:platinum') }}</td>
        <td><input type="number" min="0" max="9999" v-model="sheet.input.currency.platinum"></td>
      </tr>
    </table>
  </div>
</template>


<script>
export default {
  props: [ 'sheet' ]
}
</script>
