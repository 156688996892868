<template>
  <div id="spells">
    <h2 class="capitalize">{{ $t('spells') }}</h2>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ]
}
</script>
