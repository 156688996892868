<template>
  <main>
    <Navbar>
      <a class="navbar__title" href="https://pfrpg.recuen.co">PFRPG<span class="hide-mobile">.recuen.co</span></a>
      <div class="navbar__buttons" slot="right">
        <button class="navbar__button capitalize" @click="handleClear">{{ $t('clear') }}</button>
        <button class="navbar__button capitalize" @click="handleSave">{{ $t('save') }}</button>
        <input type="file" id="load" @input="handleLoad" hidden ref="load">
        <label class="navbar__button capitalize" for="load" tabindex="0" @keydown.enter.space.prevent="handleChooseFile">{{ $t('load') }}</label>
        <LocaleSwitcher class="navbar__button" />
      </div>
    </Navbar>
    <CharacterSheet :sheet="sheet" />
    <div v-if="isDev" style="padding: 0 1rem">
      <h2>Debug JSON input</h2>
      <textarea style="width: 100%; box-sizing: border-box" :value="stringify(source)" @input="handleUpdateSource" rows="30"></textarea>
    </div>
  </main>
</template>

<script>
import { saveAs } from 'file-saver'
import { createSheet } from '../core'
import { defaultSheet } from '../core/defaults'
import Navbar from './components/Navbar'
import CharacterSheet from './components/CharacterSheet'
import LocaleSwitcher from './components/LocaleSwitcher'

export default {
  data() {
    return {
      isDev: process.env.NODE_ENV !== 'production',
      source: { ...defaultSheet }
    }
  },
  computed: {
    sheet() {
      return createSheet(this.source)
    }
  },
  methods: {
    handleClear() {
      this.source = { ...defaultSheet }
    },
    handleSave() {
      const blob = new Blob([JSON.stringify(this.source)], { type: 'application/json;charset=utf-8' })
      const profile = this.source.profile
      const fileName = `${profile.character_name}-${profile.class}-lv${profile.level}.json`

      saveAs(blob, fileName)
    },
    handleLoad(event) {
      const input = event.target
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        try {
          this.source = JSON.parse(reader.result)
        } catch {}
      })
      reader.readAsText(input.files[0])
      input.value = null
    },
    handleChooseFile() {
      this.$refs.load.click()
    },
    handleUpdateSource(event) {
      try {
        this.source = JSON.parse(event.target.value)
      } catch {}
    },
    stringify(data) {
      return JSON.stringify(data, null, 2)
    }
  },
  components: {
    Navbar,
    CharacterSheet,
    LocaleSwitcher
  }
}
</script>
