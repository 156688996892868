<template>
  <div class="locale-changer">
    <select :value="$i18n.locale" @change.passive="handleChange">
      <option v-for="lang in langs" :key="lang" :value="lang">
        {{ $t(`language:${lang}`) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: [ 'language' ],
  data() {
    return { langs: ['es', 'en'] }
  },
  methods: {
    handleChange(event) {
      const value = event.target.value

      this.$i18n.locale = value
      localStorage.setItem('lang', value)
    }
  }
}
</script>
