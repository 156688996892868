const { attributes } = require('./enums')

const defaultProfile = Object.freeze({
  player_name: '',
  character_name: '',
  alignment: '',
  class: '',
  level: 0,
  kind: '',
  deity: '',
  age: 0,
  gender: '',
  weight: 0,
  height: 0
})

const defaultAttributes = Object.freeze({
  [attributes.strength]: 10,
  [attributes.dexterity]: 10,
  [attributes.constitution]: 10,
  [attributes.intelligence]: 10,
  [attributes.wisdom]: 10,
  [attributes.charisma]: 10
})

const defaultModifiers = Object.freeze({
  initiative: 0
})

const defaultSkills = [
  { name: 'default_skill:acrobatics', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:appraise', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:bluff', attr: attributes.charisma, level: 0, modifiers: 0 },
  { name: 'default_skill:craft', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:climb', attr: attributes.strength, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:diplomacy', attr: attributes.charisma, level: 0, modifiers: 0 },
  { name: 'default_skill:disable_device', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:disguise', attr: attributes.charisma, level: 0, modifiers: 0 },
  { name: 'default_skill:escape_artist', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:fly', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:handle_animal', attr: attributes.charisma, level: 0, modifiers: 0 },
  { name: 'default_skill:heal', attr: attributes.wisdom, level: 0, modifiers: 0 },
  { name: 'default_skill:intimidate', attr: attributes.charisma, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_arcana', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_dungeoneering', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_engineering', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_geography', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_local', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_nature', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_nobility', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_planes', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:knowledge_religion', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:linguistics', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:perception', attr: attributes.wisdom, level: 0, modifiers: 0 },
  { name: 'default_skill:perform', attr: attributes.charisma, level: 0, modifiers: 0 },
  { name: 'default_skill:profession', attr: attributes.wisdom, level: 0, modifiers: 0 },
  { name: 'default_skill:ride', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:sense_motive', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:sleight_of_hand', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:spellcraft', attr: attributes.intelligence, level: 0, modifiers: 0 },
  { name: 'default_skill:stealth', attr: attributes.dexterity, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:survival', attr: attributes.wisdom, level: 0, modifiers: 0 },
  { name: 'default_skill:swim', attr: attributes.strength, level: 0, modifiers: 0, armorPenalty: true },
  { name: 'default_skill:use_magic_device', attr: attributes.charisma, level: 0, modifiers: 0 }
]

const defaultCurrency = Object.freeze({
  copper: 0,
  silver: 0,
  gold: 0,
  platinum: 0
})

const defaultSheet = Object.freeze({
  profile: { ...defaultProfile },
  attributes: { ...defaultAttributes },
  modifiers: { ...defaultModifiers },
  health: 0,
  wounds: 0,
  skills: [ ...defaultSkills ],
  items: [],
  traits: '',
  features: '',
  notes: '',
  currency: { ...defaultCurrency }
})

module.exports = {
  defaultProfile,
  defaultAttributes,
  defaultModifiers,
  defaultSkills,
  defaultCurrency,
  defaultSheet
}
