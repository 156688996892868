<template>
  <div id="profile" class="full-width">
    <h2 class="capitalize">{{ $t('profile') }}</h2>
    <div class="form capitalize">
      <div class="form__row">
        <div class="form__field">
          <label class="form__label" for="">{{ $t('player_name') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.player_name">
        </div>
      </div>
      <div class="form__row">
        <div class="form__field">
          <label class="form__label" for="">{{ $t('character_name') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.character_name">
        </div>
      </div>
      <div class="form__row">
        <div class="form__field">
          <label class="form__label" for="">{{ $t('kind') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.kind">
        </div>
        <div class="form__field">
          <label class="form__label" for="">{{ $t('gender') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.gender">
        </div>
        <div class="form__field">
          <label class="form__label" for="">{{ $t('class') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.class">
        </div>
        <div class="form__field">
          <label class="form__label" for="">{{ $t('level') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.level">
        </div>
      </div>
      <div class="form__row">
        <div class="form__field">
          <label class="form__label" for="">{{ $t('alignment') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.alignment">
        </div>
        <div class="form__field">
          <label class="form__label" for="">{{ $t('deity') }}</label>
          <input class="form__input" type="text" v-model="sheet.input.profile.deity">
        </div>
      </div>
      <div class="form__row">
        <div class="form__field">
          <label class="form__label" for="">{{ $t('age') }}</label>
          <input class="form__input" type="number" min="1" max="999" v-model="sheet.input.profile.age">
        </div>
        <div class="form__field">
          <label class="form__label" for="">{{ $t('weight') }} (lb)</label>
          <input class="form__input" type="number" min="1" max="999" v-model="sheet.input.profile.weight">
        </div>
        <div class="form__field">
          <label class="form__label" for="">{{ $t('height') }} (ft)</label>
          <input class="form__input" type="number" min="1" max="999" v-model="sheet.input.profile.height">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ]
}
</script>
