<template>
  <div id="notes">
    <h2 class="capitalize">{{ $t('notes') }}</h2>
    <div>
      <textarea class="notes" v-model="sheet.input.notes" rows="10"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'sheet' ]
}
</script>
